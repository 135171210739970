

























































import { debounceProcess } from "@/helpers/debounce";
import { QrcodeStream } from "vue-qrcode-reader";
import Vue from "vue";
export default Vue.extend({
  components: {
    QrcodeStream,
  },
  data() {
    this.setInnerWidth = debounceProcess(this.setInnerWidth, 200);
    return {
      camera: "auto",
      result: null as null | string,
      content: null as null | string,
      showScanConfirmation: false,
      innerWidth: window.innerWidth as null | number,
    };
  },
  methods: {
    async onDecode(content) {
      this.content = content;
      this.result = `${window.location.protocol}//${window.location.host}/qr/detail/${content}`;
      this.pause();
      await this.timeout(500);
      this.unpause();
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms);
      });
    },

    handleCancel() {
      this.result = null;
    },

    setInnerWidth(value: number | null) {
      this.innerWidth = value;
    },
  },
  mounted() {
    window.addEventListener("resize", (e: UIEvent) => {
      const width = e.target as Window;
      this.setInnerWidth(width.innerWidth);
    });
  },
});
